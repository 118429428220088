import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _30ada274 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _34a905f5 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _24cf0670 = () => interopDefault(import('../pages/bauma2022.vue' /* webpackChunkName: "pages/bauma2022" */))
const _4ef0f48b = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _5959e7d0 = () => interopDefault(import('../pages/case.vue' /* webpackChunkName: "pages/case" */))
const _7a95e840 = () => interopDefault(import('../pages/charity.vue' /* webpackChunkName: "pages/charity" */))
const _74126380 = () => interopDefault(import('../pages/city-and-guilds.vue' /* webpackChunkName: "pages/city-and-guilds" */))
const _52637892 = () => interopDefault(import('../pages/claas.vue' /* webpackChunkName: "pages/claas" */))
const _916e6d38 = () => interopDefault(import('../pages/clearance.vue' /* webpackChunkName: "pages/clearance" */))
const _1b9c70c0 = () => interopDefault(import('../pages/conditions.vue' /* webpackChunkName: "pages/conditions" */))
const _03786e68 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _67fd8d4c = () => interopDefault(import('../pages/cookie.vue' /* webpackChunkName: "pages/cookie" */))
const _418cf61a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _436fd1e9 = () => interopDefault(import('../pages/densoair.vue' /* webpackChunkName: "pages/densoair" */))
const _23fcd5c2 = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _2633a1b2 = () => interopDefault(import('../pages/fendt.vue' /* webpackChunkName: "pages/fendt" */))
const _f1e821d2 = () => interopDefault(import('../pages/hebonilube-automatic.vue' /* webpackChunkName: "pages/hebonilube-automatic" */))
const _7d9392b1 = () => interopDefault(import('../pages/issuu.vue' /* webpackChunkName: "pages/issuu" */))
const _33a2a76a = () => interopDefault(import('../pages/jcb-agricultural.vue' /* webpackChunkName: "pages/jcb-agricultural" */))
const _cff9cf86 = () => interopDefault(import('../pages/jcb-construction.vue' /* webpackChunkName: "pages/jcb-construction" */))
const _dbd5ef46 = () => interopDefault(import('../pages/john-deere.vue' /* webpackChunkName: "pages/john-deere" */))
const _239a46cc = () => interopDefault(import('../pages/kubota.vue' /* webpackChunkName: "pages/kubota" */))
const _6bc852fe = () => interopDefault(import('../pages/massey-ferguson.vue' /* webpackChunkName: "pages/massey-ferguson" */))
const _e29f1d6a = () => interopDefault(import('../pages/new-holland.vue' /* webpackChunkName: "pages/new-holland" */))
const _c38051c8 = () => interopDefault(import('../pages/new-products/index.vue' /* webpackChunkName: "pages/new-products/index" */))
const _4f18407e = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _b844e13c = () => interopDefault(import('../pages/oem/index.vue' /* webpackChunkName: "pages/oem/index" */))
const _82480d8e = () => interopDefault(import('../pages/oksys.vue' /* webpackChunkName: "pages/oksys" */))
const _1dbee5b6 = () => interopDefault(import('../pages/oilcrossreference.vue' /* webpackChunkName: "pages/oilcrossreference" */))
const _df299e5a = () => interopDefault(import('../pages/password-forgotten/index.vue' /* webpackChunkName: "pages/password-forgotten/index" */))
const _6ccdacec = () => interopDefault(import('../pages/poweredge.vue' /* webpackChunkName: "pages/poweredge" */))
const _5aa479d0 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _3a56c462 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _362e8bc7 = () => interopDefault(import('../pages/john-deere-tractor.vue' /* webpackChunkName: "pages/john-deere-tractor" */))
const _f5e0b992 = () => interopDefault(import('../pages/return-policy.vue' /* webpackChunkName: "pages/return-policy" */))
const _2ec48ed4 = () => interopDefault(import('../pages/sanden-compressors.vue' /* webpackChunkName: "pages/sanden-compressors" */))
const _d4f74700 = () => interopDefault(import('../pages/schaeffler-belts.vue' /* webpackChunkName: "pages/schaeffler-belts" */))
const _407b191f = () => interopDefault(import('../pages/sds-sheets-en.vue' /* webpackChunkName: "pages/sds-sheets-en" */))
const _c0ebfdda = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _faa3a822 = () => interopDefault(import('../pages/spal-blower-motors.vue' /* webpackChunkName: "pages/spal-blower-motors" */))
const _63da3450 = () => interopDefault(import('../pages/spal-condenser.vue' /* webpackChunkName: "pages/spal-condenser" */))
const _1269ae80 = () => interopDefault(import('../pages/tba-terms-conditions.vue' /* webpackChunkName: "pages/tba-terms-conditions" */))
const _5913b838 = () => interopDefault(import('../pages/warranty.vue' /* webpackChunkName: "pages/warranty" */))
const _2aeab9fe = () => interopDefault(import('../pages/cart/data.vue' /* webpackChunkName: "pages/cart/data" */))
const _f2155b58 = () => interopDefault(import('../pages/cart/failed.vue' /* webpackChunkName: "pages/cart/failed" */))
const _4ff3a968 = () => interopDefault(import('../pages/cart/thankyou.vue' /* webpackChunkName: "pages/cart/thankyou" */))
const _1b2e3ed3 = () => interopDefault(import('../pages/search/SearchResultsProducts.vue' /* webpackChunkName: "pages/search/SearchResultsProducts" */))
const _65b95ab6 = () => interopDefault(import('../pages/search/SearchResultsVehicles.vue' /* webpackChunkName: "pages/search/SearchResultsVehicles" */))
const _4d619f36 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _65d41b4e = () => interopDefault(import('../pages/new-products/_product/index.vue' /* webpackChunkName: "pages/new-products/_product/index" */))
const _bdf4d190 = () => interopDefault(import('../pages/oem/_oem.vue' /* webpackChunkName: "pages/oem/_oem" */))
const _d2dda5e8 = () => interopDefault(import('../pages/password-forgotten/_token/index.vue' /* webpackChunkName: "pages/password-forgotten/_token/index" */))
const _2c9dedb8 = () => interopDefault(import('../pages/product/_product.vue' /* webpackChunkName: "pages/product/_product" */))
const _21033996 = () => interopDefault(import('../pages/products/_group/index.vue' /* webpackChunkName: "pages/products/_group/index" */))
const _15060e42 = () => interopDefault(import('../pages/search/_product/index.vue' /* webpackChunkName: "pages/search/_product/index" */))
const _4c42caf7 = () => interopDefault(import('../pages/products/_group/_brand/index.vue' /* webpackChunkName: "pages/products/_group/_brand/index" */))
const _0708227e = () => interopDefault(import('../pages/products/_group/_brand/_type/index.vue' /* webpackChunkName: "pages/products/_group/_brand/_type/index" */))
const _259b0c8e = () => interopDefault(import('../pages/products/_group/_brand/_type/_model/index.vue' /* webpackChunkName: "pages/products/_group/_brand/_type/_model/index" */))
const _593abd27 = () => interopDefault(import('../pages/products/_group/_brand/_type/_model/_product/index.vue' /* webpackChunkName: "pages/products/_group/_brand/_type/_model/_product/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _30ada274,
    name: "about-us___en"
  }, {
    path: "/account",
    component: _34a905f5,
    name: "account___en"
  }, {
    path: "/bauma-2022",
    component: _24cf0670,
    name: "bauma2022___en"
  }, {
    path: "/cart",
    component: _4ef0f48b,
    name: "cart___en"
  }, {
    path: "/case",
    component: _5959e7d0,
    name: "case___en"
  }, {
    path: "/charity-and-sponsorship",
    component: _7a95e840,
    name: "charity___en"
  }, {
    path: "/city-and-guilds",
    component: _74126380,
    name: "city-and-guilds___en"
  }, {
    path: "/claas",
    component: _52637892,
    name: "claas___en"
  }, {
    path: "/clearance",
    component: _916e6d38,
    name: "clearance___en"
  }, {
    path: "/conditions",
    component: _1b9c70c0,
    name: "conditions___en"
  }, {
    path: "/contact",
    component: _03786e68,
    name: "contact___en"
  }, {
    path: "/cookie",
    component: _67fd8d4c,
    name: "cookie___en"
  }, {
    path: "/de",
    component: _418cf61a,
    name: "index___de"
  }, {
    path: "/denso-air-conditioning-compressors",
    component: _436fd1e9,
    name: "densoair___en"
  }, {
    path: "/favourites",
    component: _23fcd5c2,
    name: "favorites___en"
  }, {
    path: "/fendt",
    component: _2633a1b2,
    name: "fendt___en"
  }, {
    path: "/fr",
    component: _418cf61a,
    name: "index___fr"
  }, {
    path: "/hebonilube-automatic-grease-pumps",
    component: _f1e821d2,
    name: "hebonilube-automatic___en"
  }, {
    path: "/ie",
    component: _418cf61a,
    name: "index___ie"
  }, {
    path: "/issuu",
    component: _7d9392b1,
    name: "issuu___en"
  }, {
    path: "/jcb-agricultural",
    component: _33a2a76a,
    name: "jcb-agricultural___en"
  }, {
    path: "/jcb-construction",
    component: _cff9cf86,
    name: "jcb-construction___en"
  }, {
    path: "/john-deere",
    component: _dbd5ef46,
    name: "john-deere___en"
  }, {
    path: "/kubota",
    component: _239a46cc,
    name: "kubota___en"
  }, {
    path: "/massey-ferguson",
    component: _6bc852fe,
    name: "massey-ferguson___en"
  }, {
    path: "/new-holland",
    component: _e29f1d6a,
    name: "new-holland___en"
  }, {
    path: "/new-products",
    component: _c38051c8,
    name: "new-products___en"
  }, {
    path: "/news",
    component: _4f18407e,
    name: "news___en"
  }, {
    path: "/oem",
    component: _b844e13c,
    name: "oem___en"
  }, {
    path: "/oksys-r134a-service-machines",
    component: _82480d8e,
    name: "oksys___en"
  }, {
    path: "/pag-oil-cross-reference-chart",
    component: _1dbee5b6,
    name: "oilcrossreference___en"
  }, {
    path: "/password-forgotten",
    component: _df299e5a,
    name: "password-forgotten___en"
  }, {
    path: "/poweredge",
    component: _6ccdacec,
    name: "poweredge___en"
  }, {
    path: "/privacy",
    component: _5aa479d0,
    name: "privacy___en"
  }, {
    path: "/products",
    component: _3a56c462,
    name: "products___en"
  }, {
    path: "/products-john-deere-tractor-parts",
    component: _362e8bc7,
    name: "john-deere-tractor___en"
  }, {
    path: "/return-policy",
    component: _f5e0b992,
    name: "return-policy___en"
  }, {
    path: "/sanden-air-conditioning-compressors",
    component: _2ec48ed4,
    name: "sanden-compressors___en"
  }, {
    path: "/schaeffler-belts-oaps-and-fead-kits",
    component: _d4f74700,
    name: "schaeffler-belts___en"
  }, {
    path: "/sds-sheets-en",
    component: _407b191f,
    name: "sds-sheets-en___en"
  }, {
    path: "/search",
    component: _c0ebfdda,
    name: "search___en"
  }, {
    path: "/spal-blower-motors",
    component: _faa3a822,
    name: "spal-blower-motors___en"
  }, {
    path: "/spal-condenser-fans",
    component: _63da3450,
    name: "spal-condenser___en"
  }, {
    path: "/tba-terms-and-conditions",
    component: _1269ae80,
    name: "tba-terms-conditions___en"
  }, {
    path: "/warranty",
    component: _5913b838,
    name: "warranty___en"
  }, {
    path: "/cart/data",
    component: _2aeab9fe,
    name: "cart-data___en"
  }, {
    path: "/cart/failed",
    component: _f2155b58,
    name: "cart-failed___en"
  }, {
    path: "/cart/thank-you",
    component: _4ff3a968,
    name: "cart-thankyou___en"
  }, {
    path: "/de/bauma-2022",
    component: _24cf0670,
    name: "bauma2022___de"
  }, {
    path: "/de/bedingungen",
    component: _1b9c70c0,
    name: "conditions___de"
  }, {
    path: "/de/case",
    component: _5959e7d0,
    name: "case___de"
  }, {
    path: "/de/charity-and-sponsorship",
    component: _7a95e840,
    name: "charity___de"
  }, {
    path: "/de/city-and-guilds",
    component: _74126380,
    name: "city-and-guilds___de"
  }, {
    path: "/de/claas",
    component: _52637892,
    name: "claas___de"
  }, {
    path: "/de/clearance",
    component: _916e6d38,
    name: "clearance___de"
  }, {
    path: "/de/cookie",
    component: _67fd8d4c,
    name: "cookie___de"
  }, {
    path: "/de/denso-air-conditioning-compressors",
    component: _436fd1e9,
    name: "densoair___de"
  }, {
    path: "/de/favourites",
    component: _23fcd5c2,
    name: "favorites___de"
  }, {
    path: "/de/fendt",
    component: _2633a1b2,
    name: "fendt___de"
  }, {
    path: "/de/garantie",
    component: _5913b838,
    name: "warranty___de"
  }, {
    path: "/de/hebonilube-automatic-grease-pumps",
    component: _f1e821d2,
    name: "hebonilube-automatic___de"
  }, {
    path: "/de/issuu",
    component: _7d9392b1,
    name: "issuu___de"
  }, {
    path: "/de/jcb-agricultural",
    component: _33a2a76a,
    name: "jcb-agricultural___de"
  }, {
    path: "/de/jcb-construction",
    component: _cff9cf86,
    name: "jcb-construction___de"
  }, {
    path: "/de/john-deere",
    component: _dbd5ef46,
    name: "john-deere___de"
  }, {
    path: "/de/kontakt",
    component: _03786e68,
    name: "contact___de"
  }, {
    path: "/de/konto",
    component: _34a905f5,
    name: "account___de"
  }, {
    path: "/de/kubota",
    component: _239a46cc,
    name: "kubota___de"
  }, {
    path: "/de/massey-ferguson",
    component: _6bc852fe,
    name: "massey-ferguson___de"
  }, {
    path: "/de/nachrichten",
    component: _4f18407e,
    name: "news___de"
  }, {
    path: "/de/new-holland",
    component: _e29f1d6a,
    name: "new-holland___de"
  }, {
    path: "/de/new-products",
    component: _c38051c8,
    name: "new-products___de"
  }, {
    path: "/de/oem",
    component: _b844e13c,
    name: "oem___de"
  }, {
    path: "/de/oksys-r134a-service-machines",
    component: _82480d8e,
    name: "oksys___de"
  }, {
    path: "/de/pag-oil-cross-reference-chart",
    component: _1dbee5b6,
    name: "oilcrossreference___de"
  }, {
    path: "/de/passwort-vergessen",
    component: _df299e5a,
    name: "password-forgotten___de"
  }, {
    path: "/de/poweredge",
    component: _6ccdacec,
    name: "poweredge___de"
  }, {
    path: "/de/privacy",
    component: _5aa479d0,
    name: "privacy___de"
  }, {
    path: "/de/products-john-deere-tractor-parts",
    component: _362e8bc7,
    name: "john-deere-tractor___de"
  }, {
    path: "/de/produkte",
    component: _3a56c462,
    name: "products___de"
  }, {
    path: "/de/ruecknahmegarantie",
    component: _f5e0b992,
    name: "return-policy___de"
  }, {
    path: "/de/sanden-air-conditioning-compressors",
    component: _2ec48ed4,
    name: "sanden-compressors___de"
  }, {
    path: "/de/schaeffler-belts-oaps-and-fead-kits",
    component: _d4f74700,
    name: "schaeffler-belts___de"
  }, {
    path: "/de/sds-sheets-en",
    component: _407b191f,
    name: "sds-sheets-en___de"
  }, {
    path: "/de/spal-blower-motors",
    component: _faa3a822,
    name: "spal-blower-motors___de"
  }, {
    path: "/de/spal-condenser-fans",
    component: _63da3450,
    name: "spal-condenser___de"
  }, {
    path: "/de/suechen",
    component: _c0ebfdda,
    name: "search___de"
  }, {
    path: "/de/tba-terms-and-conditions",
    component: _1269ae80,
    name: "tba-terms-conditions___de"
  }, {
    path: "/de/ueber-uns",
    component: _30ada274,
    name: "about-us___de"
  }, {
    path: "/de/warenkorb",
    component: _4ef0f48b,
    name: "cart___de"
  }, {
    path: "/fr/a-propos-de-nous",
    component: _30ada274,
    name: "about-us___fr"
  }, {
    path: "/fr/actualites",
    component: _4f18407e,
    name: "news___fr"
  }, {
    path: "/fr/bauma-2022",
    component: _24cf0670,
    name: "bauma2022___fr"
  }, {
    path: "/fr/case",
    component: _5959e7d0,
    name: "case___fr"
  }, {
    path: "/fr/charity-and-sponsorship",
    component: _7a95e840,
    name: "charity___fr"
  }, {
    path: "/fr/city-and-guilds",
    component: _74126380,
    name: "city-and-guilds___fr"
  }, {
    path: "/fr/claas",
    component: _52637892,
    name: "claas___fr"
  }, {
    path: "/fr/clearance",
    component: _916e6d38,
    name: "clearance___fr"
  }, {
    path: "/fr/compte",
    component: _34a905f5,
    name: "account___fr"
  }, {
    path: "/fr/conditions",
    component: _1b9c70c0,
    name: "conditions___fr"
  }, {
    path: "/fr/contact",
    component: _03786e68,
    name: "contact___fr"
  }, {
    path: "/fr/cookie",
    component: _67fd8d4c,
    name: "cookie___fr"
  }, {
    path: "/fr/denso-air-conditioning-compressors",
    component: _436fd1e9,
    name: "densoair___fr"
  }, {
    path: "/fr/favourites",
    component: _23fcd5c2,
    name: "favorites___fr"
  }, {
    path: "/fr/fendt",
    component: _2633a1b2,
    name: "fendt___fr"
  }, {
    path: "/fr/garantie",
    component: _5913b838,
    name: "warranty___fr"
  }, {
    path: "/fr/hebonilube-automatic-grease-pumps",
    component: _f1e821d2,
    name: "hebonilube-automatic___fr"
  }, {
    path: "/fr/issuu",
    component: _7d9392b1,
    name: "issuu___fr"
  }, {
    path: "/fr/jcb-agricultural",
    component: _33a2a76a,
    name: "jcb-agricultural___fr"
  }, {
    path: "/fr/jcb-construction",
    component: _cff9cf86,
    name: "jcb-construction___fr"
  }, {
    path: "/fr/john-deere",
    component: _dbd5ef46,
    name: "john-deere___fr"
  }, {
    path: "/fr/kubota",
    component: _239a46cc,
    name: "kubota___fr"
  }, {
    path: "/fr/massey-ferguson",
    component: _6bc852fe,
    name: "massey-ferguson___fr"
  }, {
    path: "/fr/mot-de-passe-oublie",
    component: _df299e5a,
    name: "password-forgotten___fr"
  }, {
    path: "/fr/new-holland",
    component: _e29f1d6a,
    name: "new-holland___fr"
  }, {
    path: "/fr/new-products",
    component: _c38051c8,
    name: "new-products___fr"
  }, {
    path: "/fr/oem",
    component: _b844e13c,
    name: "oem___fr"
  }, {
    path: "/fr/oksys-r134a-service-machines",
    component: _82480d8e,
    name: "oksys___fr"
  }, {
    path: "/fr/pag-oil-cross-reference-chart",
    component: _1dbee5b6,
    name: "oilcrossreference___fr"
  }, {
    path: "/fr/panier",
    component: _4ef0f48b,
    name: "cart___fr"
  }, {
    path: "/fr/politique-de-retour",
    component: _f5e0b992,
    name: "return-policy___fr"
  }, {
    path: "/fr/poweredge",
    component: _6ccdacec,
    name: "poweredge___fr"
  }, {
    path: "/fr/privacy",
    component: _5aa479d0,
    name: "privacy___fr"
  }, {
    path: "/fr/products-john-deere-tractor-parts",
    component: _362e8bc7,
    name: "john-deere-tractor___fr"
  }, {
    path: "/fr/produits",
    component: _3a56c462,
    name: "products___fr"
  }, {
    path: "/fr/rechercher",
    component: _c0ebfdda,
    name: "search___fr"
  }, {
    path: "/fr/sanden-air-conditioning-compressors",
    component: _2ec48ed4,
    name: "sanden-compressors___fr"
  }, {
    path: "/fr/schaeffler-belts-oaps-and-fead-kits",
    component: _d4f74700,
    name: "schaeffler-belts___fr"
  }, {
    path: "/fr/sds-sheets-en",
    component: _407b191f,
    name: "sds-sheets-en___fr"
  }, {
    path: "/fr/spal-blower-motors",
    component: _faa3a822,
    name: "spal-blower-motors___fr"
  }, {
    path: "/fr/spal-condenser-fans",
    component: _63da3450,
    name: "spal-condenser___fr"
  }, {
    path: "/fr/tba-terms-and-conditions",
    component: _1269ae80,
    name: "tba-terms-conditions___fr"
  }, {
    path: "/ie/about-us",
    component: _30ada274,
    name: "about-us___ie"
  }, {
    path: "/ie/account",
    component: _34a905f5,
    name: "account___ie"
  }, {
    path: "/ie/bauma-2022",
    component: _24cf0670,
    name: "bauma2022___ie"
  }, {
    path: "/ie/cart",
    component: _4ef0f48b,
    name: "cart___ie"
  }, {
    path: "/ie/case",
    component: _5959e7d0,
    name: "case___ie"
  }, {
    path: "/ie/charity-and-sponsorship",
    component: _7a95e840,
    name: "charity___ie"
  }, {
    path: "/ie/city-and-guilds",
    component: _74126380,
    name: "city-and-guilds___ie"
  }, {
    path: "/ie/claas",
    component: _52637892,
    name: "claas___ie"
  }, {
    path: "/ie/clearance",
    component: _916e6d38,
    name: "clearance___ie"
  }, {
    path: "/ie/conditions",
    component: _1b9c70c0,
    name: "conditions___ie"
  }, {
    path: "/ie/contact",
    component: _03786e68,
    name: "contact___ie"
  }, {
    path: "/ie/cookie",
    component: _67fd8d4c,
    name: "cookie___ie"
  }, {
    path: "/ie/denso-air-conditioning-compressors",
    component: _436fd1e9,
    name: "densoair___ie"
  }, {
    path: "/ie/favourites",
    component: _23fcd5c2,
    name: "favorites___ie"
  }, {
    path: "/ie/fendt",
    component: _2633a1b2,
    name: "fendt___ie"
  }, {
    path: "/ie/hebonilube-automatic-grease-pumps",
    component: _f1e821d2,
    name: "hebonilube-automatic___ie"
  }, {
    path: "/ie/issuu",
    component: _7d9392b1,
    name: "issuu___ie"
  }, {
    path: "/ie/jcb-agricultural",
    component: _33a2a76a,
    name: "jcb-agricultural___ie"
  }, {
    path: "/ie/jcb-construction",
    component: _cff9cf86,
    name: "jcb-construction___ie"
  }, {
    path: "/ie/john-deere",
    component: _dbd5ef46,
    name: "john-deere___ie"
  }, {
    path: "/ie/kubota",
    component: _239a46cc,
    name: "kubota___ie"
  }, {
    path: "/ie/massey-ferguson",
    component: _6bc852fe,
    name: "massey-ferguson___ie"
  }, {
    path: "/ie/new-holland",
    component: _e29f1d6a,
    name: "new-holland___ie"
  }, {
    path: "/ie/new-products",
    component: _c38051c8,
    name: "new-products___ie"
  }, {
    path: "/ie/news",
    component: _4f18407e,
    name: "news___ie"
  }, {
    path: "/ie/oem",
    component: _b844e13c,
    name: "oem___ie"
  }, {
    path: "/ie/oksys-r134a-service-machines",
    component: _82480d8e,
    name: "oksys___ie"
  }, {
    path: "/ie/pag-oil-cross-reference-chart",
    component: _1dbee5b6,
    name: "oilcrossreference___ie"
  }, {
    path: "/ie/password-forgotten",
    component: _df299e5a,
    name: "password-forgotten___ie"
  }, {
    path: "/ie/poweredge",
    component: _6ccdacec,
    name: "poweredge___ie"
  }, {
    path: "/ie/privacy",
    component: _5aa479d0,
    name: "privacy___ie"
  }, {
    path: "/ie/products",
    component: _3a56c462,
    name: "products___ie"
  }, {
    path: "/ie/products-john-deere-tractor-parts",
    component: _362e8bc7,
    name: "john-deere-tractor___ie"
  }, {
    path: "/ie/return-policy",
    component: _f5e0b992,
    name: "return-policy___ie"
  }, {
    path: "/ie/sanden-air-conditioning-compressors",
    component: _2ec48ed4,
    name: "sanden-compressors___ie"
  }, {
    path: "/ie/schaeffler-belts-oaps-and-fead-kits",
    component: _d4f74700,
    name: "schaeffler-belts___ie"
  }, {
    path: "/ie/sds-sheets-en",
    component: _407b191f,
    name: "sds-sheets-en___ie"
  }, {
    path: "/ie/search",
    component: _c0ebfdda,
    name: "search___ie"
  }, {
    path: "/ie/spal-blower-motors",
    component: _faa3a822,
    name: "spal-blower-motors___ie"
  }, {
    path: "/ie/spal-condenser-fans",
    component: _63da3450,
    name: "spal-condenser___ie"
  }, {
    path: "/ie/tba-terms-and-conditions",
    component: _1269ae80,
    name: "tba-terms-conditions___ie"
  }, {
    path: "/ie/warranty",
    component: _5913b838,
    name: "warranty___ie"
  }, {
    path: "/search/SearchResultsProducts",
    component: _1b2e3ed3,
    name: "search-SearchResultsProducts___en"
  }, {
    path: "/search/SearchResultsVehicles",
    component: _65b95ab6,
    name: "search-SearchResultsVehicles___en"
  }, {
    path: "/de/search/SearchResultsProducts",
    component: _1b2e3ed3,
    name: "search-SearchResultsProducts___de"
  }, {
    path: "/de/search/SearchResultsVehicles",
    component: _65b95ab6,
    name: "search-SearchResultsVehicles___de"
  }, {
    path: "/de/warenkorb/dankr",
    component: _4ff3a968,
    name: "cart-thankyou___de"
  }, {
    path: "/de/warenkorb/data",
    component: _2aeab9fe,
    name: "cart-data___de"
  }, {
    path: "/de/warenkorb/error",
    component: _f2155b58,
    name: "cart-failed___de"
  }, {
    path: "/fr/panier/data",
    component: _2aeab9fe,
    name: "cart-data___fr"
  }, {
    path: "/fr/panier/failed",
    component: _f2155b58,
    name: "cart-failed___fr"
  }, {
    path: "/fr/panier/thank-you",
    component: _4ff3a968,
    name: "cart-thankyou___fr"
  }, {
    path: "/fr/search/SearchResultsProducts",
    component: _1b2e3ed3,
    name: "search-SearchResultsProducts___fr"
  }, {
    path: "/fr/search/SearchResultsVehicles",
    component: _65b95ab6,
    name: "search-SearchResultsVehicles___fr"
  }, {
    path: "/ie/cart/data",
    component: _2aeab9fe,
    name: "cart-data___ie"
  }, {
    path: "/ie/cart/failed",
    component: _f2155b58,
    name: "cart-failed___ie"
  }, {
    path: "/ie/cart/thank-you",
    component: _4ff3a968,
    name: "cart-thankyou___ie"
  }, {
    path: "/ie/search/SearchResultsProducts",
    component: _1b2e3ed3,
    name: "search-SearchResultsProducts___ie"
  }, {
    path: "/ie/search/SearchResultsVehicles",
    component: _65b95ab6,
    name: "search-SearchResultsVehicles___ie"
  }, {
    path: "/de/nachrichten/:slug",
    component: _4d619f36,
    name: "news-slug___de"
  }, {
    path: "/de/new-products/:product",
    component: _65d41b4e,
    name: "new-products-product___de"
  }, {
    path: "/de/oem/:oemNumber",
    component: _bdf4d190,
    name: "oem-oem___de"
  }, {
    path: "/de/passwort-vergessen/:token",
    component: _d2dda5e8,
    name: "password-forgotten-token___de"
  }, {
    path: "/de/produkt/:product",
    component: _2c9dedb8,
    name: "product-product___de"
  }, {
    path: "/de/produkte/:group",
    component: _21033996,
    name: "products-group___de"
  }, {
    path: "/de/suechen/:product",
    component: _15060e42,
    name: "search-product___de"
  }, {
    path: "/fr/actualites/:slug",
    component: _4d619f36,
    name: "news-slug___fr"
  }, {
    path: "/fr/mot-de-passe-oublie/:token",
    component: _d2dda5e8,
    name: "password-forgotten-token___fr"
  }, {
    path: "/fr/new-products/:product",
    component: _65d41b4e,
    name: "new-products-product___fr"
  }, {
    path: "/fr/oem/:oemNumber",
    component: _bdf4d190,
    name: "oem-oem___fr"
  }, {
    path: "/fr/produit/:product",
    component: _2c9dedb8,
    name: "product-product___fr"
  }, {
    path: "/fr/produits/:group",
    component: _21033996,
    name: "products-group___fr"
  }, {
    path: "/fr/rechercher/:product",
    component: _15060e42,
    name: "search-product___fr"
  }, {
    path: "/ie/new-products/:product",
    component: _65d41b4e,
    name: "new-products-product___ie"
  }, {
    path: "/ie/news/:slug",
    component: _4d619f36,
    name: "news-slug___ie"
  }, {
    path: "/ie/oem/:oemNumber",
    component: _bdf4d190,
    name: "oem-oem___ie"
  }, {
    path: "/ie/password-forgotten/:token",
    component: _d2dda5e8,
    name: "password-forgotten-token___ie"
  }, {
    path: "/ie/product/:product",
    component: _2c9dedb8,
    name: "product-product___ie"
  }, {
    path: "/ie/products/:group",
    component: _21033996,
    name: "products-group___ie"
  }, {
    path: "/ie/search/:product",
    component: _15060e42,
    name: "search-product___ie"
  }, {
    path: "/de/produkte/:group/:brand",
    component: _4c42caf7,
    name: "products-group-brand___de"
  }, {
    path: "/fr/produits/:group/:brand",
    component: _4c42caf7,
    name: "products-group-brand___fr"
  }, {
    path: "/ie/products/:group/:brand",
    component: _4c42caf7,
    name: "products-group-brand___ie"
  }, {
    path: "/de/produkte/:group/:brand/:type",
    component: _0708227e,
    name: "products-group-brand-type___de"
  }, {
    path: "/fr/produits/:group/:brand/:type",
    component: _0708227e,
    name: "products-group-brand-type___fr"
  }, {
    path: "/ie/products/:group/:brand/:type",
    component: _0708227e,
    name: "products-group-brand-type___ie"
  }, {
    path: "/de/produkte/:group/:brand/:type/:model",
    component: _259b0c8e,
    name: "products-group-brand-type-model___de"
  }, {
    path: "/fr/produits/:group/:brand/:type/:model",
    component: _259b0c8e,
    name: "products-group-brand-type-model___fr"
  }, {
    path: "/ie/products/:group/:brand/:type/:model",
    component: _259b0c8e,
    name: "products-group-brand-type-model___ie"
  }, {
    path: "/de/produkte/:group/:brand/:type/:model/:product",
    component: _593abd27,
    name: "products-group-brand-type-model-product___de"
  }, {
    path: "/fr/produits/:group/:brand/:type/:model/:product",
    component: _593abd27,
    name: "products-group-brand-type-model-product___fr"
  }, {
    path: "/ie/products/:group/:brand/:type/:model/:product",
    component: _593abd27,
    name: "products-group-brand-type-model-product___ie"
  }, {
    path: "/new-products/:product",
    component: _65d41b4e,
    name: "new-products-product___en"
  }, {
    path: "/news/:slug",
    component: _4d619f36,
    name: "news-slug___en"
  }, {
    path: "/oem/:oemNumber",
    component: _bdf4d190,
    name: "oem-oem___en"
  }, {
    path: "/password-forgotten/:token",
    component: _d2dda5e8,
    name: "password-forgotten-token___en"
  }, {
    path: "/product/:product",
    component: _2c9dedb8,
    name: "product-product___en"
  }, {
    path: "/products/:group",
    component: _21033996,
    name: "products-group___en"
  }, {
    path: "/search/:product",
    component: _15060e42,
    name: "search-product___en"
  }, {
    path: "/products/:group/:brand",
    component: _4c42caf7,
    name: "products-group-brand___en"
  }, {
    path: "/products/:group/:brand/:type",
    component: _0708227e,
    name: "products-group-brand-type___en"
  }, {
    path: "/products/:group/:brand/:type/:model",
    component: _259b0c8e,
    name: "products-group-brand-type-model___en"
  }, {
    path: "/products/:group/:brand/:type/:model/:product",
    component: _593abd27,
    name: "products-group-brand-type-model-product___en"
  }, {
    path: "/",
    component: _418cf61a,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
